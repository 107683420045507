import client from './requestclient';

const userCredentials = {
    userData: async (id) => {
        return await client({URL_PATH : `getuserbyid/${id}`, method : "GET"})
    },

    update: async (body) => {
        return await client({URL_PATH : "updaterealtor", method : "POST", body : body});
    },

    profilePic: async (formData) => {
        return await client({URL_PATH: "uploaduserphoto", method: "POST", body: formData, bodyType: "FormData"});
    },

    userIdentity: async (formData) => {
        return await client({URL_PATH: "uploaduseridentity", method: "POST", body: formData, bodyType: "FormData"});
    },

    dashboardSummary: async (id) => {
        return await client({URL_PATH: `fetchdashboardsummary/${id}`, method: "GET"});
    },
    userSubscribed: async (id) => {
        return await client({URL_PATH : `checkforsmartsubscription/${id}`, method : "GET"})
    },
}

export default userCredentials;