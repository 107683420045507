<template>
    <div class="holder mt-3">
        <div class="row">
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-6">
                        <div class="top-left-holder">
                            <div class="top-left">
                                <h6>Balance</h6>
                                <h3>{{ formattedBalance(availableBalance) }} <span class="d-none" style="margin-left: 10px;"><i class="fa fa-eye-slash fa-1x"></i></span></h3> 
                                <div class="bonus-section">
                                    <p>Bonus: <span> {{ formattedBalance(bonusBalance) }}</span></p>
                                    <p>Expected <span>:</span><span>{{  formattedBalance(expectedBalance) }}</span></p>
                                </div>
                                <div class="btn-holder">
                                    <button class="btn">
                                        <router-link to="/user/dashboard/wallet" style="color: inherit; text-decoration: none;">
                                            Withdraw
                                        </router-link>
                                    </button>
                                    <button class="btn">
                                        <router-link to="/user/dashboard/point-logs" style="color: inherit; text-decoration: none;">
                                            View Points Log <span style="color: #0033ea;">({{ toTwoDecimal(readyPoints) }})</span>
                                        </router-link>
                                    </button>
                                </div>
                            </div>
                            <img class="eclips" src="../assets/icons/green-eclips.svg" alt="eclips">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="avmfi-holder d-none">
                            <div class="smart-fm-io">
                                <img src="../assets/icons/smart-fmio.svg" alt="smart/fm-io">
                                <h5>Smart FM/IO</h5>
                            </div>
                            <div class="flex justify-content-between agent-vendor-holder">
                                <div class="smart-agent" >
                                    <!-- <router-link to="/user/smart-agent"> -->
                                    <router-link to="/user/dashboard">
                                        <img src="../assets/icons/smart-agent.svg" alt="smart-agent">
                                        <h5>Smart Agent</h5>
                                    </router-link>
                                </div>
                                <div class="smart-vendor">
                                    <img src="../assets/icons/smart-vendor.svg" alt="smart-vendor">
                                    <h5>Smart Vendor</h5>
                                </div>
                            </div>
                        </div>
                        <div class="promo-progress">
                            <h6>Q3 Smart promo progress</h6>
                            <img class="pin-image" src="../assets/images/black-color.svg" alt="black">
                            <img class="pin-image2" src="../assets/images/yellow-color.svg" alt="yellow">
                            <div class="row">
                                <div class="col-md-4">
                                    <img class="pp-img" src="../assets/icons/profile-promo.svg" alt="profile">
                                </div>
                                <div class="col-md-8 remove-padding">
                                    <!-- <div class="position-text mb-3">
                                        <p>My position</p>
                                        <h5>{{ promoData.loggedinuser_position || 0 }}</h5>
                                    </div> -->
                                    <div class="row promo-details">
                                        <div class="col-6 position-text mb-3">
                                            <p>My position</p>
                                            <h5>{{ promoData.loggedinuser_position || 0 }}</h5>
                                        </div>
                                        <div class="col-6 position-text mb-3">
                                            <p>My points</p>
                                            <h5>{{ promoData.loggedinuser_point || 0 }}</h5>
                                        </div>
                                        
                                        <div class="col-md-12 mb-3">
                                            <div class="row" style="flex-wrap: nowrap;">
                                                <div class="col-md-6">
                                                    <p>Current milestone</p>
                                                    <h5>{{ promoData.loggedinuser_milestone || 0}}</h5>
                                                </div>
                                                <div class="col-md-6" v-if="promoData">
                                                    <p>Current reward</p>
                                                    <h5>₦{{ Number(promoData.loggedinuser_reward).toLocaleString() || 0}}</h5>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12 mb-3">
                                            <div class="row" style="flex-wrap: nowrap;">
                                                <div class="col-md-6">
                                                    <p>Next milestone</p>
                                                    <h5>{{ promoData.loggedinuser_next_milestone || 0}}</h5>
                                                </div>
                                                <div class="col-md-6" v-if="promoData">
                                                    <p>Next reward</p>
                                                    <h5>₦{{ Number(promoData.loggedinuser_next_reward).toLocaleString() || 0}}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="promo-btn">
                                <router-link to="/promo">
                                    <button class="btn">Promo Details</button>
                                </router-link>
                           </div>
                        </div>
                    </div>
                </div>
                <div class="second-left-holder">
                    <div class="second-left">
                        <div class="d-flex justify-content-between align-items-center top-text">
                            <h5>Transactions</h5>
                            <h5>
                                <router-link to="/user/dashboard/transactions" style="color: inherit; text-decoration: none;">
                                    See More
                                </router-link>
                            </h5>
                        </div>
                        <div class="m-auto text-center mt-4" v-if="tLoading"><Loader/></div>
                        <div class="empty text-center mt-4 " v-if="propertyTransactions.length == 0 && !tLoading">No Records</div>
                        <div v-else class="transaction-lists mt-4" v-for="transaction in propertyTransactions.slice(0, 5)" :key="transaction.id">
                            <div class="d-flex justify-content-between align-item-center mb-4">
                                <div class="d-flex justify-content-between align-items-start tlh">
                                    <img src="../assets/icons/green-bg-arrow-down.svg" alt="">
                                    <div>
                                        <h6 v-if="transaction.description">{{transaction.description}} Pin-{{ transaction.pin }}</h6>
                                        <h6>{{formatDate(transaction.created_at)}}</h6>
                                    </div>
                                </div>
                                <div>
                                    <h6 class="amount"><td>₦{{formatAmount(transaction.amount)}}</td></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <!-- <div class="mb-4">
                    <router-link to="/promo" style="display: contents;" class="display: content;">
                        <img style="cursor: pointer; width: 100%;" src="../assets/images/promo-card.svg" alt="promo">
                    </router-link>
                </div> -->

                <div class="opportunity-desk-top" v-if="oppCategories.length">
                    <div class="d-flex justify-content-between align-items-center opportuity-desk-text">
                        <h5>Opportunity Desk</h5>
                        <!-- <h5>See All</h5> -->
                    </div>
                    <div class="opp-category-holder">
                        <div class="opportunity-buttons" v-for="category in oppCategories" :key="category.id">
                            <button @click=navigateToMoreOpp(category.id) class="bth">{{ category.type }}</button>
                            <!-- <router-link to="opportunity-more">
                                
                            </router-link> -->
                            <!-- <button class="bth">Investments</button>
                            <button class="bth">For sale</button>
                            <button class="bth">To Let</button>
                            <button class="bth">Own-It</button>
                            <button class="bth">Co-Own</button>
                            <button class="bth">Smart agent</button>
                            <button class="bth">Smart Guarantor</button>
                            <button class="bth">Smart FM/IO</button> -->
                        </div>
                    </div>
                    <div class="mt-4 inner-carousel" v-if="opportunityToLet.length > 0">
                        <div class="inner-slider" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
                            <div class="property-list" v-for="(item, index) of opportunityToLet.slice(0, 3)" :key="index">
                                <div @click=navigateToMoreOpp(2)>
                                    <h6>{{ item.title }}</h6>
                                    <p>{{ item.description }}</p>
                                    <h5><span>{{ Number(item.amount).toLocaleString() }}</span>/Annually</h5>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="slide-button">
                            <button class="btn" :class="[currentIndex == 0 ? 'active-slide' : '']"></button>
                            <button class="btn" :class="[currentIndex == 1 ? 'active-slide' : '']"></button>
                            <button class="btn" :class="[currentIndex == 2 ? 'active-slide' : '']"></button>
                        </div>
                    </div>
                </div>

                <div class="opportunity-desk-top commission-history-holder mt-4">
                    <div class="d-flex justify-content-between align-items-center opportuity-desk-text">
                        <h5>Commission history</h5>
                        <h5>See All</h5>
                    </div>
                    <div class="m-auto text-center mt-4" v-if="cLoading"><Loader/></div>
                    <div class="empty text-center mt-4 " v-if="commissionHistory.length == 0 && !cLoading">No Commission</div>
                    <div v-else class="commission-lists" v-for="commission in commissionHistory.slice(0, 3)" :key="commission.id">
                        <div class="commission-type">
                            <h5>{{commission.description}}</h5>
                            <h5 class="d-none d-md-block">{{formatDate(commission.created_at)}}</h5>
                        </div>
                        <h4>Amount: <span>₦{{formatAmount(commission.amount)}}</span></h4>
                        <h4 class="d-block d-md-none">{{formatDate(commission.created_at)}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <WithdrawalModal></WithdrawalModal>
    </div>
</template>

<script>
import { getPromoTableDetails } from "../services/promo";
import CF from "../helpers/currencyFormatter";
import formatDate from "../helpers/extractDate";
import currencyFormatter from "../helpers/currencyFormatter";
import userCredentials from "../services/userCredentials";
import realtor from "../services/realtor";
import transactions from "../services/transactions";
import opportunities from "../services/opportunityDesk";
import Loader from './Loader';
import WithdrawalModal from './WithdrawalModal';

// import $ from "jquery";
export default {
  name: "Dashboard2",
  components: {
    Loader,
    WithdrawalModal,
  },
  data() {
    return {
        loading: false,
        tLoading: false,
        cLoading: false,
        loadingProperty: false,
        currentIndex: 0,
        interval: null,
        opportunityToLet: [],
        propItem: [
            {
            title: "3 bedroom fully detached duplex1",
            description: "Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non ...",
            amount: 2000000
            },
            {
            title: "2 bedroom fully detached duplex2",
            description: "Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non ...",
            amount: 2000000
            },
            {
            title: "3 bedroom fully detached duplex3",
            description: "Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non ...",
            amount: 2000000
            }
        ],
        userID: null,
        userToken: null,
        availableBalance: 0,
        expectedBalance: 0,
        bonusBalance: 0,
        readyPoints: null,
        expectedPoints: null,
        transactionCount: null,
        propertyTransactions: [],
        commissionHistory: [],
        oppCategories: [],
        promoData: "",
    };
  },
  created() {
    const user = this.$store.getters.getUser;
    this.userID = user.id;
    this.userToken = user.token;
    this.$root.$refs.user = this;

    // I had to go through all this stress because the data returned when a user logs in
    // is not as complete as when you call the /getuserbyid GET api.

    // for example the returned login object does not have a city object and state object inside
    // of it, passportfile is name instead of url

    // but the object returned when you call getuserbyid has a city object, state object and the
    // passportfile property is a url and not a name.
    if (
      !user.passportfile ||
      user.passportfile.includes("https://") === false
    ) {
      this.getUserFullDetails();
    }
    this.getDashboardSummary();
    this.getUserActivationDataAmount();

    this.tLoading = true;
    transactions.propertyTransactions(this.userID)
    .then(data => {
        this.tLoading = false;
        if (data.success) {
            this.propertyTransactions = data.transactions;
        } else {
            this.$toast.error(data.error);
        }
    })
    .catch(() => {
        this.tLoading = false;
        this.$toast.error("please check your network and refresh the page");
    })
    .finally(() => {
        this.tLoading = false;
    });
    
    // commission history
    this.cLoading = true;
    transactions.commissionHistory(this.userID)
    .then(data => {
        // console.log("commissiions", data);
        this.cLoading = false;
        if (data.success) {
            this.commissionHistory = data.transactions;
        } else {
            this.$toast.error(data.error);
        }
    })
    .catch(() => {
        this.cLoading = false;
        this.$toast.error("please check your network and refresh the page");
    })
    .finally(() => {
        this.tLoading = false;
    });
  },
  mounted() {
    this.startAutoSlide();
    this.opportunityCategories();
    this.opportunityByCategory();
    this.fetchPromoDetails();
  },
  beforeDestroy() {
    this.stopAutoSlide();
  },
  methods: {
    // opportunity-more
    navigateToMoreOpp(id) {
        this.$router.push({path: `opportunity-more/${id}`});
    },
    formatDate(date) {
        return formatDate(date);
    },
    formatAmount(value) {
        return currencyFormatter.toTwoDecimal(value);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.propItem.length;
    },
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.propItem.length) % this.propItem.length;
    },
    startAutoSlide() {
      this.interval = setInterval(this.nextSlide, 4000);
    },
    stopAutoSlide() {
      clearInterval(this.interval);
    },
    formattedBalance(amount) {
      return CF.naira.format(amount);
    },
    toTwoDecimal(value) {
      return CF.toTwoDecimal(value);
    },
    async opportunityByCategory() {
        this.loadingProperty = true;
        await opportunities.opportunityDeskByCategoryId(2).then(data => {
            // console.log("Opportunitiescategories:", data);
            this.loadingProperty = false;
            if (data.success) {
                this.opportunityToLet = data.data;
            } else {
                this.$toast.error(data.error);
            }
        }).catch(() => {
            this.loadingProperty = false;
            this.$toast.error("please check your network and refresh the page");
        });
    }, 
    getUserFullDetails() {
      userCredentials
        .userData(this.userID)
        .then(data => {
          if (data.success) {
            data.user.token = this.userToken;
            this.$store.dispatch("saveUser", data.user);
          } else {
            this.$toast.error(data.error);
          }
        })
        .catch(() => {
          this.$toast.error("please check your network and refresh the page");
        });
    },
    opportunityCategories() {
        opportunities.opportunityCategory().then(data => {
            // console.log("categories:", data);
            if (data.success) {
                this.oppCategories = data.data;
            } else {
                this.$toast.error(data.error);
            }
        }).catch(() => {
          this.$toast.error("please check your network and refresh the page");
        });
    },
    getDashboardSummary() {
      userCredentials
        .dashboardSummary(this.userID)
        .then(data => {
          if (data.success) {
            this.availableBalance = data.readywallet;
            this.expectedBalance = data.expectedwallet;
            this.transactionCount = data.transactions;
            this.readyPoints = data.readypoint;
            this.expectedPoints = data.expectedpoint;
          } else {
            this.$toast.error(data.error);
          }
        })
        .catch(() => {
          this.$toast.error("please check your network and refresh the page");
        });
    },
    getUserActivationDataAmount() {
      realtor
        .fetchActivationData()
        .then(data => {
          if (data.activateddata) {
            this.bonusBalance =
              data.activateddata.status == 2 ? 0 : data.activateddata.amount;
          } else {
            this.bonusBalance = 0;
          }
        })
        .catch(err => {
          console.log(err);

          this.$toast.error("Please check your network and refresh page");
        });
    },
    async fetchPromoDetails() {
        await getPromoTableDetails().then(data => {
            // console.log("Promo details:", data);
            if (data.success) {
                this.promoData = data;
            } else {
                this.$toast.error(data.error);
            }
        }).catch(() => {
            this.loading = false;
            this.$toast.error("please check your network and refresh the page");
        });
    },
    
  },
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$sheltaBlack: #2B3352;

.top-left-holder {
    background:$white;
    border-radius: 10px;
    padding: 24px;
    position: relative;
    height: 268px;
}
.top-left {
    h6, h3 {
        font-family: Lato;
        text-align: left;
        color: $asheGrey;
    }
    h6 {
        font-size: 14px;
        font-weight: 600;
        line-height: 17.5px;
    }
    h3 {
        font-size: 36px;
        font-weight: 700;
        line-height: 45px;
    }
}

.bonus-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    p {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: $asheGrey;
    }
}

.btn-holder {
    display: flex;
    gap: 10px;
    button {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        border-radius: 50px;
        height: 32px;
        outline: 0;
        box-shadow: none;
    }
    button:first-child {
        background-color: $primary;
        color: $white;
    }
    button:nth-child(2) {
        border: 1px solid $primary;
        color: $primary;
    }
}

.promo-progress {
    background: $white;
    border-radius: 10px;
    padding: 16px;
    position: relative;
    h6 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #2B3352;
    }
}

.promo-details, .position-text {
    p {
        font-family: Lato;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;
        color: #555C74;
       
    }
    h5 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
        color: #2B3352;
        
    }
}

.remove-padding {
    padding: 0;
    h6 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #2B3352;
    }
}
.pin-image {
    position: absolute;
    right: 0;
    top: 0;
    border-top-right-radius: 10px;
}
.pin-image2 {
    position: absolute;
    right: 0;
    top: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.promo-btn {
    text-align: center;
    // margin-top: 6px;
    button {
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.5px;
        text-align: left;
        color: $white;
        background: #242424;
        box-shadow: none;
        outline: 0;
    }
    a {
        display: contents;
        text-decoration: none;
        color: inherit;
    }
}

.eclips {
    position: absolute;
    right: 0;
    bottom: 0;
    border-bottom-right-radius: 10px;
}

.avmfi-holder {
    border-radius: 10px;
    padding: 16px;
    background: $white;
}
.smart-fm-io {
    background: #FFF7E6;
    margin-bottom: 15px;
}
.smart-agent {
    background: #FFECE5;
    a {
        color: inherit;
        text-decoration: none;
        color: inherit;
        display: contents;
    }
}
.smart-vendor {
    background: #F4F0FE;
}
.agent-vendor-holder {
    gap: 15px;
}
.smart-fm-io, .smart-agent, .smart-vendor {
    border-radius: 10px;
    padding: 16px;
    height: 111px; 
    width: 100%;
    cursor: pointer;
    h5 {
        padding-top: 15px;
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 22.5px;
        text-align: left;
        color: $sheltaBlack;
    }
}

.second-left-holder {
    background:$white;
    border-radius: 10px;
    padding: 24px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.second-left {
    .top-text {
        h5 {
            font-family: Lato;
            font-size: 16px;
            font-weight: 500;
            color: $asheGrey;
        }
        h5:first-child {
            font-weight: 500;
        }
        h5:nth-child(2) {
            font-weight: 700;
            color: $primary;
        }
    }
}
.tlh {
    gap: 10px;
    div {
        h6 {
            font-family: Lato;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: $asheGrey;
            margin-bottom: 0;
        }
    }
}

.amount {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: $asheGrey;
    margin-bottom: 0;
}

.opportunity-desk-top {
    background: $white;
    padding: 16px;
    border-radius: 10px;
}
.opp-category-holder {
    display: ruby-text;
}
.opportunity-buttons {
    // display: flex;
    // flex-direction: row;
    // gap: 10px;
    a {
        margin-right: 7px;
        color: inherit;
        text-decoration: none;
        color: inherit;
        display: contents;
    }
    button {
        border: 1px solid #A1A2A3;
        background-color: transparent;
        color: $asheGrey;
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.5px;
        text-align: center;
        height: 40px;
        // width: fit-content;
        border-radius: 50px;
        padding: 10px 12px;
        margin-right: 7px !important;
        margin-bottom: 10px;
    }
    button:last-child {
        margin-right: 0;
    }
    button:hover {
        background: $primary;
        color: $white;
        border: none;
    }
}

.opportuity-desk-text {
    margin-bottom: 20px;
    h5 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        color: $asheGrey;
    }
    h5:first-child {
        font-weight: 500;
    }
    h5:nth-child(2) {
        font-weight: 700;
        color: $primary;
        cursor: pointer;
    }
}

.inner-carousel {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: auto;
    overflow: hidden;
}
.inner-slider {
   display: flex;
   transition: transform 0.5s ease;
}
.property-list {
    min-width: 100%;
    box-sizing: border-box;
    h6, p, h5 {
        font-family: Lato;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        color: #2B3352;
    }
    h6 {
        font-weight: 600;
    }
    p {
        font-weight: 400;
        color: $asheGrey;
        margin-bottom: 10px !important;
    }
    h5 {
        font-weight: 400;
        text-align: justified;
        color: $primary;
        span {
            font-weight: 600;
        }
    }
}

.slide-button {
    text-align: center;
    button {
        background: blue;
        width: 8px;
        height: 8px;
        border-radius: 50px;
        padding: inherit;
        margin: 0 5px;
    }
}

.active-slide {
    border: 4px solid $primary;
    padding: 3px !important;
    background: $white !important;
}

.commission-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    h5 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: $asheGrey;
    }
    h5:first-child{
        background: #F8F8F8;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        border-left: 2px solid $yellow;
        padding: 4px 8px;
    }
}

.commission-lists {
    margin-bottom: 20px;
    border-bottom: 1px solid #EBECEC;
    h4 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: $asheGrey;
        // margin-top: 5px;
    }
}
.commission-lists:last-child {
    border-bottom: none;
}

@media screen and (max-width: 599px) {
  .eclips {
   display: none;
  }
  .top-left-holder {
    height: fit-content;
    margin-bottom: 30px;
  }
  .promo-details, .position-text {
    p, h5 {
        text-align: center;
    }
  }
  .pp-img {
    margin: auto;
    display: flex;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .pin-image, .pin-image2 {
    display: none;
  }
  .promo-progress {
    h6 {
        text-align: center;
    }
  }
  .remove-padding {
    padding: 15px;
  }
}
</style>